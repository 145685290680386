import React from "react"
import { css } from "@emotion/core"
import { FormattedMessage } from "react-intl"
import Button from "../utils/button"

const SubscribeForm = () => (
  <form name="subscribe" method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value="subscribe" />
    <div
      css={css`
        display: flex;
        justify-content: center;
        margin: 0px auto;

        input {
          height: 100%;
          box-sizing: border-box;
          width: 95%;
        }
      `}
    >
      <div
        css={css`
          margin-right: 5px;
          flex: auto;
        `}
      >
        <input type="email" name="email" required />
      </div>
      <Button>
        <FormattedMessage id="contact.subscribe_btn_text" />
      </Button>
    </div>
  </form>
)

export default SubscribeForm
