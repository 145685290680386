import React from "react"
import { css } from "@emotion/core"
import { FormattedMessage } from "react-intl"
import Button from "../utils/button"

const ContactForm = () => (
  <form name="contact" method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value="contact" />
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0px auto;

        > div {
          flex: 0.48;
          color: white;
          margin: 8px 0px;
          padding: 0px 5px;

          input,
          textarea {
            width: 100%;
            padding: 5px 5px;
            margin: 4px 0px;
            font-size: 16px;
          }
        }
      `}
    >
      <div>
        <span>
          <FormattedMessage id="contact.firstname" /> *
        </span>
        <input type="text" name="firstname" required />
      </div>
      <div>
        <span>
          <FormattedMessage id="contact.lastname" /> *
        </span>
        <input type="text" name="lastname" required />
      </div>
      <div
        css={css`
          flex: 100% !important;
        `}
      >
        <span>
          <FormattedMessage id="contact.emailaddress" /> *
        </span>
        <input type="email" name="email" required />
      </div>
      <div
        css={css`
          flex: 100% !important;
        `}
      >
        <span>
          <FormattedMessage id="contact.subject" /> *
        </span>
        <input type="text" name="subject" required />
      </div>
      <div
        css={css`
          flex: 100% !important;
        `}
      >
        <span>
          <FormattedMessage id="contact.message" /> *
        </span>
        <textarea rows="4" name="message" required />
      </div>
    </div>
    <div
      css={css`
        width: 15%;
        margin: 10px auto;
      `}
    >
      <Button>
        <FormattedMessage id="contact.send_btn_text" />
      </Button>
    </div>
  </form>
)

export default ContactForm
