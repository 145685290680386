import React from "react"
import { css } from "@emotion/core"
import { FormattedMessage } from "react-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact/contact-form"
import SubscribeForm from "../components/contact/subscribe-form"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div
      css={css`
        width: 70%;
        margin: 50px auto;

        @media (max-width: 640px) {
          width: 90%;
        }
      `}
    >
      <div
        css={css`
          margin: 30px auto;
          margin-bottom: 60px;
          width: 90%;
          max-width: 400px;
        `}
      >
        <h2
          css={css`
            font-weight: 500;
            text-transform: uppercase;
            color: #e5cccc;
            text-align: center;
            margin-bottom: 0.5em;

            :lang(en) {
              letter-spacing: 0.1em;
            }
          `}
        >
          <FormattedMessage id="contact.subscribe" />:
        </h2>
        <SubscribeForm />
      </div>
      <div>
        <h2
          css={css`
            font-weight: 500;
            text-transform: uppercase;
            color: #e5cccc;
            letter-spacing: 0.1em;
            text-align: center;

            :lang(en) {
              letter-spacing: 0.1em;
            }
          `}
        >
          <FormattedMessage id="contact.contactus" />:
        </h2>
        <ContactForm />
      </div>
    </div>
  </Layout>
)

export default Contact
